import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import { SvgSpinnersBarsScale } from '@/assets/icons/layout/SvgSpinnersBarsScale';
import { BaseEmpty } from '@/components/base/baseEmpty';
import { BaseFlex } from '@/components/base/baseFlex';
import { cn } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import React, { ReactNode } from 'react';

interface BaseLoadingDisplayProps {
  /**
   * 加载
   * @param {boolean} loading
   */
  loading?: boolean;
  /**
   * 列表
   * @param {any[]} list
   */
  list: any[];

  className?: string;

  children?: ReactNode;

  isShowDisplayLoading?: boolean; //是否展示内置的 loading 和 空状态，默认为 true，可以关闭

  customEmpty?: React.ReactNode; // 自定义空状态
}

export function BaseLoadingDisplay(props: BaseLoadingDisplayProps) {
  const { loading, list, children, className, isShowDisplayLoading = true } = props;
  const { isMobile } = useModel('system');
  return (
    <div className="relative">
      {isShowDisplayLoading && loading && (
        <BaseFlex
          align="center"
          justify="center"
          flex={1}
          className={cn(className, {
            'h-full absolute top-0 left-0 right-0 bottom-0 flex justify-center min-h-[70px]':
              !isMobile,
            'h-16': isMobile,
          })}
        >
          {/* 加载图标 */}
          <SvgSpinnersBarsScale className="text-2xl text-primary" />
        </BaseFlex>
      )}
      {!loading && (!list || list?.length === 0) && (props.customEmpty ?? <BaseEmpty />)}
      {list?.length !== 0 && children}
    </div>
  );
}
