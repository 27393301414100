import classNames from 'classnames';
import {HTMLAttributes, ReactNode} from 'react';

/**
 *
 * @param props
 * @returns BaseFlex
 */
export function BaseFlex(props: XFlexProps) {
  const {
    vertical = false,
    wrap = false,
    justify = 'start',
    align = 'start',
    flex,
    component = 'div',
    children,
    className,
    ...reset
  } = props;

  const Component = component;

  const flexClasses = classNames(
    'flex',
    {
      'flex-row': !vertical,
      'flex-col': vertical,
      'flex-wrap': wrap,
      'flex-no-wrap': !wrap,
      'justify-start': justify === 'start',
      'justify-end': justify === 'end',
      'justify-center': justify === 'center',
      'justify-between': justify === 'between',
      'justify-around': justify === 'around',
      'items-start': align === 'start',
      'items-end': align === 'end',
      'items-center': align === 'center',
      'items-stretch': align === 'stretch',
    },
    className,
  );

  let flexStyles: React.CSSProperties = {};

  if (typeof flex === 'number' || typeof flex === 'string') {
    flexStyles.flex = flex;
    flexStyles.width = '100%';
  } else if (Array.isArray(flex)) {
    flexStyles.flexGrow = flex[0];
    flexStyles.flexShrink = flex[1];
    flexStyles.flexBasis = flex[2];
  }

  return (
    <Component className={flexClasses} style={flexStyles} {...reset as any}>
      {children}
    </Component>
  );
}

export interface XFlexProps extends Partial<HTMLAttributes<HTMLDivElement>> {
  /**
   * 是否垂直布局
   * @default false
   * @type boolean
   */
  vertical?: boolean;
  /**
   * 是否换行
   * @default false
   * @type boolean
   */
  wrap?: boolean;
  /**
   * 主轴对齐方式
   * @default 'start'
   * @type 'start' | 'end' | 'center' | 'between' | 'around'
   */
  justify?: 'start' | 'end' | 'center' | 'between' | 'around';
  /**
   * 交叉轴对齐方式
   * @default 'start'
   * @type 'start' | 'end' | 'center' | 'baseline' | 'stretch'
   */
  align?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  /**
   * flex 值
   * @type string | number
   */
  flex?: string | number;
  /**
   * 组件
   * @default 'div'
   * @type keyof JSX.IntrinsicElements
   */
  component?: keyof JSX.IntrinsicElements;
  /**
   * 子元素
   * @type ReactNode
   */
  children?: ReactNode;
}
