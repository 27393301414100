import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import { getNoticeList } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import useUrlState from '@ahooksjs/use-url-state';
import { cn } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * pc 的文章
 */
export default () => {
  const { t } = useTranslation();

  // 选中的
  const [selected, setSelected] = useState<any>(null);

  const {
    data = [] as any,
    runAsync: getNoticeListApi,
    loading,
  } = useReq(getNoticeList, {
    manual: true,
    cacheKey: 'CacheEnum.APP_NOTICELISTasd',
    onSuccess(data: any[]) {
      if (selected === null && data?.length) {
        setSelected(data?.[0]);
      }
    },
  });

  const [urlState] = useUrlState<any>({});
  const { type, id } = urlState || {};

  useEffect(() => {
    if (data?.length) {
      const find = data?.find((item: any) => {
        return item?.id === id;
      });
      if (find) setSelected(find);
    }
  }, [data]);

  useEffect(() => {
    if (type)
      getNoticeListApi({
        code: type,
      });
  }, [type]);

  return (
    <div className="pt-3">
      <div className="flex space-x-2">
        <div className="w-[220px] h-[100vh] bg-backgroundAuxiliaryColor py-5 px-6 rounded-md ">
          <div>
            {data?.map((item: any) => {
              return (
                <div
                  onClick={() => setSelected(item)}
                  className={cn(
                    ' rounded-sm px-2.5 py-2 text-sm font-mono  mb-3 cursor-pointer',
                    {
                      'bg-pcPrimary': selected?.id === item.id,
                      ' text-auxiliaryTextColor': selected?.id !== item.id,
                    },
                  )}
                >
                  {item?.title}
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex-1 bg-backgroundAuxiliaryColor/50 rounded-md p-5">
          <BaseLoadingDisplay loading={loading} list={data}>
            <FeaturesRenderText data={selected} />
          </BaseLoadingDisplay>
        </div>
      </div>
    </div>
  );
};
