import { TextTypeEnum } from '@/enums/businessEnum';
import ReactMarkdown from 'react-markdown';

import { cn, decodeHTMLEntitiesOptimized } from '@/utils';
import 'highlight.js/styles/atom-one-dark.css';
import rehypeHighlight from 'rehype-highlight';
import style from './index.less';

// 渲染 后端的 富文本 或者 macdown
export default function FeaturesRenderText({
  data = {},
  className = '',
}: IProps) {
  // console.log('协议内容:',data.content)

  return (
    <div className={`${style.renderTextBox} ${className}`}>
      {data?.editorType === TextTypeEnum.RICH_TEXT && (
        <div
          className="bg-background w-full  "
          dangerouslySetInnerHTML={{
            __html: data?.content,
          }}
        ></div>
      )}
      {data?.editorType === TextTypeEnum.MARKDOWN && (
        <ReactMarkdown
          rehypePlugins={[rehypeHighlight]}
          className={cn(
            `prose prose-zinc max-w-none dark:prose-invert bg-background w-full ${className}`,
          )}
          children={decodeHTMLEntitiesOptimized(data?.content)}
        ></ReactMarkdown>
      )}
    </div>
  );
}

interface IProps {
  data: any;
  className?: string;
}
