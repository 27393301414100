import { useTranslation } from 'react-i18next';

export function BaseEmpty(props: EmptyProps) {
  const { t } = useTranslation();

  const { message = t('暂无更多数据') } = props;

  return (
    <div className="w-full text-center">
      <div className="pt-4 flex">
        {/* <MaterialSymbolsQuickReferenceAllOutline className="text-auxiliaryTextColor text-5xl" /> */}
        <img
          src={require('@/assets/img/emp.png')}
          className="w-[80px] h-[80px] m-auto"
        />
      </div>
      <div className="text-sm bounce text-auxiliaryTextColor pt-2">
        {message}
      </div>
    </div>
  );
}

export interface EmptyProps {
  message?: string;
  messageColor?: string;
}
